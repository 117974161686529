<template>

  <div>

    <div class="row mt-3">
      <div class="col-md-2">
        <div class="">
          <b-form-group
              class="mb-0">
            <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
            ></b-form-select>
          </b-form-group>
        </div>
      </div>
      <div class="col-md-2">
        <a href="#" class="btn btn-success" @click="generateReport()">
          <i class="fas fa-file-pdf"></i> Download
        </a>
      </div>
      <div class="col-md-8">
        <b-form-group
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0">
          <b-input-group size="sm">
            <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"/>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </div>

    <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :filename="`${user.fullname}`"
        :manual-pagination="true"
        pdf-format="a4"
        ref="html2Pdf">
      <section slot="pdf-content">
        <TransactionStatementPdf :items="items" :user="user" :user-id="this.$route.params.id"/>
      </section>
    </vue-html2pdf>


    <div class="row mt-5 align-items-center">
      <div class="col-md-4">
        <label for="">From</label>
        <input type="date" v-model="startDate" class="form-control">
      </div>
      <div class="col-md-4">
        <label for="">To</label>
        <input type="date" v-model="endDate" class="form-control">
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <b-table
            striped hover
            responsive
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :filter="filter"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
            :filter-included-fields="filterOn"
            thead-class="bg-primary text-white"
            show-empty
            class="mt-10">


          <template #cell(transactions)="row">
            <div v-if="row.item.transaction_date">
              <div>
                {{ row.item.transaction_date }}
              </div>
            </div>
            <div v-else>
              <b-badge variant="danger" class="font-size-10"> Data not found</b-badge>
            </div>
          </template>

          <template #cell(description)="row">
            <div v-if="row.item.description">
              <div>
                {{ row.item.description }}
              </div>
            </div>
            <div v-else>
              <b-badge variant="danger" class="font-size-10"> Data not found</b-badge>
            </div>
          </template>

          <template #cell(debit)="row">
            <div v-if="row.item.debit">
              <div>
                {{ row.item.debit }}
              </div>
            </div>
            <div v-else>
               0.00
            </div>
          </template>

          <template #cell(credit)="row">
            <div v-if="row.item.credit">
              <div>
                {{ row.item.credit }}
              </div>
            </div>
            <div v-else>
              0.00
            </div>
          </template>

          <template #cell(balance)="row">
            <div v-if="row.item.balance">
              <div>
                {{ row.item.balance }}
              </div>
            </div>
            <div v-else>
              <b-badge variant="danger" class="font-size-10"> Data not found</b-badge>
            </div>
          </template>

        </b-table>
      </div>
    </div>

    <div class="row mt-3" v-if="items">
      <div class="col-md-12">
        <b-pagination

            v-model="currentPage"
            :total-rows="items.length"
            :per-page="perPage"
            size="lg"
            class="my-0"
        ></b-pagination>
      </div>
    </div>


  </div>

</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import {jsPDF} from "jspdf";
import autoTable from 'jspdf-autotable'
import html2canvas from 'html2canvas'
import VueHtml2pdf from 'vue-html2pdf'
import TransactionStatementPdf from "./TransactionStatementPdf";

export default {
  name: "TransactionStatement",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    TransactionStatementPdf,
    // eslint-disable-next-line vue/no-unused-components
    VueHtml2pdf,
  },
  data() {
    return {
      pdf: false,
      downloadLoading: true,
      filename: '',
      autoWidth: true,
      bookType: 'xlsx',
      columns: [
        {
          key: 'id',
          label: 'Id',
        },
        {
          key: 'fullname',
          label: 'Fullname',
        },
        {
          key: 'slug',
          label: 'Slug',
        },
        {
          key: 'phone_number',
          label: 'Phone number',
        },
        {
          key: 'age',
          label: 'Age',
        },
        {
          key: 'wallet',
          label: 'Wallet',
        },
        {
          key: 'action',
          label: 'Action'
        }
      ],
      loading: false,
      currentPage: 1,
      perPage: 20,
      pageOptions: [10, 20, 40, 60, {value: 100, text: "Show a lot"}],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      localActive: false,
      items: [],
      fields: [
        {
          key: 'transactions',
          label: 'Transaction date',
          sortable: true
        },
        {
          key: 'description',
          label: 'Description',
          sortable: true
        },
        {
          key: 'debit',
          label: 'Debit',
          sortable: true
        },
        {
          key: 'credit',
          label: 'Credit',
          sortable: true
        },
        {
          key: 'balance',
          label: 'Balance',
          sortable: true
        },


      ],
      filter: null,
      filterOn: [],
      filters: {
        transaction_date: '',
        description: '',
        debit: '',
        credit: '',
        balance: '',
      },
      startDate: null,
      endDate: null,
      show: true,
      base_url: process.env.VUE_APP_API_URL,
      response_data: [],
      user: {},
      prevRoute: null
    }
  },
  metaInfo: {
    title: 'Orko Health Ltd | Transaction Statement',
    htmlAttrs: {
      lang: 'en-US'
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.path
    })
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Agent wise Transaction Statement", route: "/agent-wise-doctor-list"},
    ]);
  },
  created() {
    this.getTransactionStatement();
    this.getUserData();
  },
  computed: {
    rows() {
      return this.users.length
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key}
          })
    },
    filtered() {

      let startDate = this.localizeDate(this.startDate);
      let endDate = this.localizeDate(this.endDate);

      const filtered = this.items.filter(item => {
        const itemDate = new Date(item.transactions.transaction_date)
        if (startDate && endDate) {
          return startDate <= itemDate && itemDate <= endDate;
        }
        if (startDate && !endDate) {
          return startDate <= itemDate;
        }
        if (!startDate && endDate) {
          return itemDate <= endDate;
        }
        return Object.keys(this.filters).every(key =>
            String(item[key]).includes(this.filters[key]))
      })


      return filtered.length > 0 ? filtered : [{
        transaction_date: '',
        description: '',
        debit: '',
        credit: '',
        balance: '',
      }]
    },
    filteredData() {

      let filterType = this.selectedType;
      let startDate = this.localizeDate(this.startDate);
      let endDate = this.localizeDate(this.endDate);

      const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
      return itemsByType
          .filter(item => {
            const itemDate = new Date(item.registration_date)
            if (startDate && endDate) {
              return startDate <= itemDate && itemDate <= endDate;
            }
            if (startDate && !endDate) {
              return startDate <= itemDate;
            }
            if (!startDate && endDate) {
              return itemDate <= endDate;
            }
            return true;
          })
    }
  },
  methods: {
    getTransactionStatement() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/report/user/transaction/statement?user_type=agent-pharmacy&from_date=&to_date=&user_id=${this.$route.params.id}&api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            this.items = response.data.data.transactions
          })
    },
    getUserData() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/user/profile/details/${this.$route.params.id}?api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            this.user = response.data.data
          })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      } else {
        return '../../../public/media/users/blank.png'
      }
    },
    localizeDate(date) {
      if (!date || !date.includes('-')) return date
      const [yyyy, mm, dd] = date.split('-')
      return new Date(`${mm}/${dd}/${yyyy}`)
    },
    formatDate(date) {
      return new Intl.DateTimeFormat('en-US', {dateStyle: 'long'}).format(new Date(date))
    },

    pdfDownloadLink() {
      window.html2canvas = html2canvas;
      const doc = new jsPDF()
      autoTable(doc, {html: '#table'})
      doc.save('table.pdf')
      // doc.html(document.querySelector("#download"), {
      //   callback: function (pdf) {
      //     pdf.save("statement.pdf")
      //   }
      // })
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    }

  }
}
</script>
<style>
.hide {
  display: none;
}

.text-green {
  color: green;
}

.is_online {
  position: relative;
}

.is_online_icon {
  position: absolute;
  left: 0;
  bottom: 4px;
  margin-left: 14px;
  font-size: 12px;
}

.user_image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
  text-align: center;
}

.font-size-10 {
  font-size: 10px;
}
</style>
